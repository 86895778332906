
import BackgroundVideoPlayer from "@/components/BackgroundVideoPlayer"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: { BackgroundVideoPlayer },
  layout: "clean",
  middleware: "unauth",
  data: () => ({
    loading: false,
    email: "",
  }),
  meta: {
    public: true,
  },
  mounted() {
    this.email = this.$route.query?.email || ""
  },
  methods: {
    async sendResetRequest() {
      this.loading = true
      try {
        const { message } = await EvercamApi.users.resetPassword(this.email)
        this.$refs.form?.reset()
        this.email = ""
        this.$notifications.success(message)
        this.$router.push("/v2/users/signin")
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.auth.send_reset_password_failed"),
          error,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
